@import url('https://fonts.googleapis.com/css2?family=Spartan&display=swap');
.App {
    text-align: center;
    font-family: 'Spartan';
    font-weight: '500';
}
body {
    background-color: #000;
    color: #fff;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    height: 80vh;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.logo {
    background: -webkit-linear-gradient(0deg, #f27121, #8a2387);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 5px;
    font-size: 2rem;
    font-weight: 400;
}
.subheader {
    font-size: 1rem;
    padding: 10px;
    margin-top: -20px;
}
.footer {
    font-size: 0.5rem;
}
.footer a {
    font-size: 0.5rem;
    color: #fff;
    text-decoration: none;
}
